import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { Invoice } from '~/shared/types';

export const useInvoices = defineStore('invoices', () => {
  const collect = ref<boolean>(false);
  const meta = ref();
  const invoices = ref();
  const releves = ref();

  const init = async (token: string, userId: string) => {
    if (!collect.value) {
      const response = await getInvoices(token, userId);
      meta.value = response._metadatas;
      invoices.value = response.data.invoices;
      releves.value = response.data.releves;
      collect.value = true;
    }
    return invoices.value.length > 0 || releves.value.length > 0;
  };

  const collectInvoices = async (pageNumber: number, rows: number): Promise<Invoice[]> => {
    const start = (pageNumber - 1) * rows;
    const end = start + rows;
    return invoices.value.slice(start, end);
  };

  const getTotalInvoices = (): number => {
    if(!invoices.value) return 0;
    return invoices.value.length;
  };

  const collectReleves = async (pageNumber: number, rows: number): Promise<Invoice[]> => {
    const start = (pageNumber - 1) * rows;
    const end = start + rows;
    return releves.value.slice(start, end);
  };

  const getTotalReleves = (): number => {
    if(!releves.value) return 0;
    return releves.value.length;
  };

  const reset = () => {
    collect.value = false;
    meta.value = undefined;
    invoices.value = undefined;
    releves.value = undefined;
  };

  return {
    collect,
    meta,
    invoices,
    releves,
    init,
    collectInvoices,
    getTotalInvoices,
    collectReleves,
    getTotalReleves,
    reset,
  };
});
